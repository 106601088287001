<template>
    <div>
        <NavbarMain/>
        <div class="mx-5 mt-5">
            <b-row no-gutters>
                <b-col class="m-auto" cols="12" sm="12" md="12" lg="5">
                    <b-row>
                        <b-col class="mb-4" cols="6">
                            <b-row @click="toPacientes()" class="card contenedor py-4 bg_primary" no-gutters>
                                <b-col class="m-auto p-auto text-center" cols="12" sm="12" md="6" lg="5">
                                    <b-img style="max-height: 50%; max-width: 50%;" :src="require('../assets/menu/Pacientes.svg')"/>
                                </b-col>
                                <b-col class="title-option m-auto pt-2 d-none d-lg-block d-md-block" cols="12" sm="12" md="6" lg="7">
                                    Control de pacientes
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col class="mb-4" cols="6">
                            <b-row @click="toRegistro()" class="card contenedor py-4 bg_primary" no-gutters>
                                <b-col class="m-auto p-auto text-center" cols="12" sm="12" md="6" lg="5">
                                    <b-img style="max-height: 50%; max-width: 50%;" :src="require('../assets/menu/Administracion.svg')"/>
                                </b-col>
                                <b-col class="title-option m-auto pt-2 d-none d-lg-block d-md-block" cols="12" sm="12" md="6" lg="7">
                                    Administración
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mb-4" cols="6">
                            <b-row @click="toEnfermeria()" class="card contenedor py-4 bg_primary" no-gutters>
                                <b-col class="m-auto p-auto text-center" cols="12" sm="12" md="6" lg="5">
                                    <b-img style="max-height: 50%; max-width: 50%;" :src="require('../assets/menu/Enfermeria.svg')"/>
                                </b-col>
                                <b-col class="title-option m-auto pt-2 d-none d-lg-block d-md-block" cols="12" sm="12" md="6" lg="7">
                                    Enferemería
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col class="mb-4" cols="6">
                            <b-row class="card contenedor py-4 bg_primary" no-gutters>
                                <b-col class="m-auto p-auto text-center" cols="12" sm="12" md="6" lg="5">
                                    <b-img style="max-height: 50%; max-width: 50%;" :src="require('../assets/menu/Almacen.svg')"/>
                                </b-col>
                                <b-col class="title-option m-auto pt-2 d-none d-lg-block d-md-block" cols="12" sm="12" md="6" lg="7">
                                    Almacén
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="p-auto m-auto d-none d-lg-block" cols="12" sm="12" md="12" lg="7">
                    <div class="pl-5 pb-5" style="width: auto; margin: 0 auto 0 auto;">
                        <b-img v-if="clinica == 'cqc'" :src="require('../assets/logos/letras_healthtech_colosio.jpg')" style="max-height: 100%; max-width: 100%;"/>
                        <b-img v-if="clinica == 'chlo'" :src="require('../assets/logos/letras_healthtech_orozco.jpg')" style="max-height: 100%; max-width: 100%;"/>
                        <b-img v-if="clinica == 'sandbox'" :src="require('../assets/logos/letras_healthtech_sandbox.jpg')" style="max-height: 100%; max-width: 100%;"/>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue"
import router from '../router'
import { clinica } from '../../settings';

export default {
    name: 'Main',
    components: { NavbarMain },
   
    data: () => ({
        clinica: clinica
    }),

    methods: {
        to(to){
            router.push({ name: to })
        },

        toPacientes() {
            router.push('especialidades')
        },
        toRegistro(){
            router.push('/registro')
        },

        toEnfermeria() {
            router.push('/control/enfermeria/pacientes')
            // router.push('enfermeria/registro/hemodinamia')
        }
    },
    destroyed() {  
        this.$destroy();
        // this.$el.parentNode.removeChild(this.$el);
    }
    
}
</script>

<style scoped>
    .title-option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .contenedor {
        color: white;
        border-radius: 15px;
        cursor: pointer;
    }
    .card{
        text-align: center;
        color: white;
        position: relative;
        -webkit-transition: all 0.2s ease 0.2s;
        transition: all 0.2s ease 0.2s;
        z-index: 1
    }
    .card:before,
    .card:before{
        top: 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }
    .card:after{
        bottom: 0;
        -webkit-transform-origin: 0 100%;
        transform-origin:  0 100%
    }
    .card:hover:before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    .card:hover:after{
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    .card:hover{
        box-shadow: 15px 15px 0px rgb(215, 215, 215);
        -webkit-transform: translateY(-10px) translateX(-10px);
        transform: translateY(-10px) translateX(-10px);
        -webkit-transition: all 0.2s ease 0.2s;
        transition: all 0.2s ease 0.3s;
    }
</style>